<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Banner
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Banner
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <input type="hidden" name="promo_banner_id" id="promo_banner_id" v-model="Banner.promo_banner_id"/>
                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            class="purple-input"
                                            label="Judul"
                                            v-model="Banner.promo_banner_title"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted"
                                                    class="purple-input"
                                                    label="Tanggal Mulai"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menuTgl = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-menu
                                            v-model="menuTgl2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted2"
                                                    class="purple-input"
                                                    label="Tanggal Akhir"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date2" @input="menuTgl2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-textarea
                                            label="Deskripsi"
                                            class="purple-input"
                                            v-model="Banner.promo_banner_desc"
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="5"
                                        justify="center"
                                >
                                    <v-file-input
                                            label="Gambar"
                                            class="purple-input"
                                            v-model="Banner.image"
                                            accept="image/*"
                                            @change="onFileChange"
                                    >
                                        <template v-slot:selection="{text}">
                                            <v-chip
                                                label
                                                color="primary">
                                                {{text}}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <v-img position="center center" v-if="Banner.promo_banner_img !== '' && Banner.promo_banner_img" :src="showImgProduct"
                                        max-width="150" max-height="150" ratio="1" contain/>
                                </v-col>

                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl, BaseUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import {Money} from 'v-money'

    export default {
        name: 'FormBanner',
        components: {
            Money
        },
        data() {
            return {
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: '',
                menuTgl: false,
                date2: new Date().toISOString().substr(0, 10),
                dateFormatted2: '',
                menuTgl2: false,

                Banner: {
                    promo_banner_id: null,
                    promo_banner_title: '',
                    promo_banner_desc: '',
                    promo_banner_img: '',
                    promo_banner_startdate: '',
                    promo_banner_enddate: '',
                    image: null,

                },
                deletedItems: [],
                isLoadData: false,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                },
            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            computedDateFormatted2() {
                return this.formatDate2(this.date2)
            },
            showImgProduct: function(){
                if(this.Banner.promo_banner_img && this.Banner.promo_banner_img != '' && !this.Banner.image){
                    let imgUrl = BaseUrl+this.Banner.promo_banner_img
                    return imgUrl
                } else
                    return this.Banner.promo_banner_img
            }
        },
        watch: {
            date: function (val) {
                this.formatDate(val)
            },
            date2: function (val) {
                this.formatDate2(val)
            }
        },
        mounted: function () {
            this.computedDateFormatted
            this.computedDateFormatted2
            let id = this.$route.params.id
            console.log('edit')
            console.log(id)
            console.log(this.Banner)
            if (id !== undefined) {
                this.dataBanner(id)
            }

        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted = `${day}-${month}-${year}`
                else
                    this.dateFormatted = this.date
            },
            formatDate2: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                if (year.length > 3)
                    this.dateFormatted2 = `${day}-${month}-${year}`
                else
                    this.dateFormatted2 = this.date2
            },
            dataBanner: function (id) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                this.isLoadData = true
                this.dialog = true
                axios
                ({method: 'get', url: ApiUrl + 'banner/' + id, headers: header})
                    .then(response => {
                        console.log(response.data.data)
                        this.dialog = false

                        if (response.data.success) {
                            this.Banner.promo_banner_id = response.data.data.promo_banner_id
                            this.Banner.promo_banner_title = response.data.data.promo_banner_title
                            this.Banner.promo_banner_desc = response.data.data.promo_banner_desc
                            this.date = response.data.data.promo_banner_startdate
                            this.date2 = response.data.data.promo_banner_enddate
                            this.Banner.promo_banner_img = response.data.data.promo_banner_img
                        } else {
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal ambil data banner'
                        }
                    })
                    .catch(error => {
                        this.dialog = false
                        console.log(error)
                    })
            },

            simpan: function () {
                console.log(this.Banner)
                this.Banner.promo_banner_startdate = this.date
                this.Banner.promo_banner_enddate = this.date2
                if (this.Banner.promo_banner_title && this.Banner.promo_banner_title !== '' &&
                    this.Banner.promo_banner_desc && this.Banner.promo_banner_desc !== '' &&
                    this.Banner.promo_banner_img && this.Banner.promo_banner_img !== '' &&
                    this.Banner.promo_banner_startdate && this.Banner.promo_banner_startdate !== '' &&
                    this.Banner.promo_banner_enddate && this.Banner.promo_banner_enddate !== '') {

                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'multipart/form-data'
                    }

                    const formData = new FormData();
                    formData.append('image', this.Banner.image)
                    if(this.Banner.promo_banner_id)
                        formData.append('banner[promo_banner_id]', this.Banner.promo_banner_id)
                    formData.append('banner[promo_banner_img]', this.Banner.promo_banner_img)
                    formData.append('banner[promo_banner_title]', this.Banner.promo_banner_title)
                    formData.append('banner[promo_banner_desc]', this.Banner.promo_banner_desc)
                    formData.append('banner[promo_banner_startdate]', this.Banner.promo_banner_startdate)
                    formData.append('banner[promo_banner_enddate]', this.Banner.promo_banner_enddate)

                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'banner/simpan', data: formData, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan                    '
                                this.Banner = {
                                    promo_banner_id: null,
                                    promo_banner_title: '',
                                    promo_banner_desc: '',
                                    promo_banner_img: '',
                                    promo_banner_startdate: '',
                                    promo_banner_enddate: '',
                                    image: null,
                                }
                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error.message, error.response)
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal simpan'
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Semua field harus diisi'
                }
            },
            onFileChange(e) {
                console.log(e)
                if(e)
                    this.Banner.promo_banner_img = URL.createObjectURL(e);
                else
                    this.Banner.promo_banner_img= ''

            }
        }
    }
</script>
